import { ErrorParser } from '@i22/rocket';

import ERROR_MESSAGES from '@/assets/01-settings/js/errors.json';

const errorParser = new ErrorParser(ERROR_MESSAGES);
export default errorParser;

/** @deprecated use "errorParser" from default export instead */
// eslint-disable-next-line default-param-last
export function getErrorMessages(data, errorMessages = ERROR_MESSAGES, customFallbackMessage) {
  const customErrorParser = new ErrorParser(errorMessages);
  const parsedMessages = customErrorParser.getMessagesSimplified(data, customFallbackMessage || ERROR_MESSAGES.unknown || 'Ein unbekannter Fehler ist aufgetreten.');
  return customErrorParser.unpackPath(parsedMessages);
}
