import serializeAction from './base';

function normalizePrice(price) {
  return price / 100;
}

function serializeCartItem(item) {
  if (item.contractNumber) {
    return {
      familyName: item.familyName || item.name,
      name: item.name,
      bundle: item.gtins && item.gtins.length > 1,
      contractNumber: item.contractNumber,
      eans: item.gtins,
      quantity: item.quantity,
      price: normalizePrice(item.monthlyPrice),
      paymentFrequencyValue: 24,
    };
  }
  return {
    familyName: item.familyName || item.name,
    name: item.name,
    bundle: item.gtins && item.gtins.length > 1,
    articleNumber: item.articleNumber,
    eans: item.gtins,
    quantity: item.quantity,
    price: normalizePrice(item.price),
  };
}

export default function serializeCartAction(action, cartItems, isKnownCustomer) {
  const items = (cartItems || []).map(serializeCartItem);
  return serializeAction({
    action,
    customerIdentified: isKnownCustomer,
    items,
  });
}
