import { mapActions, mapGetters } from 'vuex';
import ErrorParser from '@/lib/error-parser';
import fetchOfferRecommendations from '@/lib/goliath/offer-recommendations';
import serializeCartAction from '@/lib/tealium/serializers/cart';
import TrackAction from '@/mixins/track-action';
import StockFromOffer from '@/lib/stock-from-offer';
import Dialog from '@/helpers/dialog';
import ShopApi from '@/lib/shop-api';
import { hasError } from '@/helpers/shop-api-errors';

export default {
  name: 'AddToCart',
  mixins: [TrackAction],
  props: {
    offer: {
      type: Object,
      required: true,
    },
    addAmountToCart: {
      type: Number,
      default: 1,
      validator: (value) => value >= 1,
    },
  },
  data() {
    return {
      isSubmitting: false,
      stockQuantity: StockFromOffer.getStockFromOffer(this.offer),
      fallbackMessage: 'Hoppla! Da ist etwas schiefgelaufen. Bitte versuche es noch einmal.',
    };
  },
  watch: {
    offer(offer) {
      this.stockQuantity = StockFromOffer.getStockFromOffer(offer);
    },
  },
  computed: {
    ...mapGetters('cart', [
      'cartItems',
      'isKnownCustomer',
      'isTariffUpgradeFlow',
      'hasBrodosProductsInCart',
      'hasTelekomContractInCart',
    ]),
    ...mapGetters('downtimes', ['maintenanceDowntime']),
    tealiumRel() {
      return 'content.button.in-den-warenkorb';
    },
    isDisabled() {
      if (this.isSubmitting) return true;
      if (this.maintenanceDowntime) return true;
      return !this.inStock;
    },
    articleNumber() {
      return this.offer?.product?.articleNumber || null;
    },
    contractNumber() {
      return this.offer?.contract?.contractNumber || null;
    },
    cartItem() {
      if (this.articleNumber === null && this.contractNumber === null) return undefined;
      return this.cartItems.find(
        (item) =>
          item.articleNumber === this.articleNumber &&
          (!this.contractNumber || item.contractNumber === this.contractNumber),
      );
    },
    cartQuantity() {
      return this.cartItem?.quantity || 0;
    },
    maxQuantity() {
      return this.cartItem?.maximumAvailable || this.offer?.maxOrderableAmount || Infinity;
    },
    stockOrMaxQuantity() {
      return this.maxQuantity < this.stockQuantity ? this.maxQuantity : this.stockQuantity;
    },
    orderableQuantity() {
      // max quantity available - currently in cart - addAmountToCart to be put in the cart
      const quantity = this.stockOrMaxQuantity - this.cartQuantity - (this.addAmountToCart - 1);

      return quantity >= 0 ? quantity : 0;
    },
    inStock() {
      return this.orderableQuantity > 0;
    },
    addToCartData() {
      return {
        articleNumber: this.articleNumber,
        contractNumber: this.contractNumber,
        quantity: this.addAmountToCart,
      };
    },
  },
  methods: {
    ...mapActions('cart', ['addCartItem', 'deleteCartItem']),
    async trackAddToCart(item) {
      try {
        await this.trackAction({
          wt_link_id: this.tealiumRel,
          event_name: 'cart_add',
          ...serializeCartAction(undefined, [item], this.isKnownCustomer),
        });
      } catch (error) {
        // do not break the process
      }
    },
    async addOfferToCart() {
      if (this.isDisabled) return;

      if (this.isTariffUpgradeFlow && this.hasTelekomContractInCart && this.articleNumber) {
        // User wants to add a brodos item to cart but has tariff in cart
        if (!await this.showTariffOrHardwareDialog()) {
          this.$dialog.hide();
          await this.$router.push('/shop/kundenformular/upgrade-identifikation');
          return;
        }
        await ShopApi.deleteOrder();
      }

      this.isSubmitting = true;
      try {
        const [{ item }, recommendedOffers] = await Promise.all([
          this.addCartItem(this.addToCartData),
          this.fetchOfferRecommendations(),
        ]);
        this.$emit('added', item);

        this.offerAddedToCart(item, recommendedOffers);

        // Track after everything is finished for a better ux.
        await this.trackAddToCart(item);
        this.trackFacebook(item);
      } catch (error) {
        this.$emit('error');
        await this.handleAddToCartError(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    offerAddedToCart(item, recommendedOffers) {
      this.$dialog.show('AddToCartDialogContent', {
        type: 'success',
        componentOptions: { item, recommendedOffers },
      });
    },
    fetchOfferRecommendations() {
      if (!this.articleNumber || this.$config.minimalMode) return [];
      try {
        return fetchOfferRecommendations({
          articleNumber: this.articleNumber,
          contractNumber: this.contractNumber,
        });
      } catch (error) {
        return [];
      }
    },
    async handleAddToCartError(error) {
      const errors = error?.response?.data?.errors || [];
      // BE returns a 404 when the product is out of stock.
      if (error?.response?.data?.status === 404) {
        errors.push({ stock: [{ error: 'exclusion' }] });
      }
      let parsedErrors = ErrorParser.getMessagesFlatten(errors, this.fallbackMessage);
      if (parsedErrors.length === 0) {
        parsedErrors = [this.fallbackMessage];
      }

      if (parsedErrors.includes('multipleTariffs')) {
        // If we have a contract in the cart, the user is prompted to decide
        // whether he wants to add the new one or keep the old one.
        this.$dialog.show('MultipleContractsInCartDialogContent', {
          type: 'warning',
          componentOptions: { newContract: this.contractNumber },
        });
        return;
      }

      if (parsedErrors.includes('multipleProvidersForbidden')) {
        // We have products from different providers in cart.
        this.$dialog.show('MultipleProviderInCartDialogContent', {
          type: 'warning',
          componentOptions: { newArticleNumber: this.articleNumber },
        });
        return;
      }

      if (hasError(error, 'stock', 'greater_than')) {
        this.stockQuantity = 0;
      }

      this.$dialog.info(parsedErrors.join('<br>'), { type: 'error' });
    },
    trackFacebook(item) {
      this.trackFBEvent('AddToCart', {
        content_ids: [item.articleNumber],
        content_name: item.name,
        content_type: 'product',
        currency: 'EUR',
        value: item.price / 100,
        num_of_items: item.quantity,
      });
    },
    showTariffOrHardwareDialog() {
      return new Promise((resolve) => {
        Dialog.show('GenericDialogContent', {
          type: 'warning',
          componentOptions: {
            lines: [
              {
                text: '<b>MagentaZuhause App Pro</b> befindet sich im Warenkorb.',
                textAlign: 'center',
                paddingBottom: '12px',
                isHtml: true,
                icon: {
                  slot: 'start',
                  name: 'check',
                  size: '18px',
                  border: false,
                },
              },
              {
                text: 'Smarte Geräte können dieser Bestellung nicht hinzugefügt werden.',
                textAlign: 'center',
                paddingBottom: '12px',
              },
              {
                text: 'Schließe die Bestellung der Pro-Version ab.',
                textAlign: 'center',
                paddingBottom: '12px',
                button: {
                  label: 'Pro-Version bestellen',
                  class: 'btn--primary',
                  handler: () => resolve(false),
                },
              },
              {
                text: 'Oder kaufe das smarte Gerät.',
                textAlign: 'center',
              },
              {
                text: '(MagentaZuhause App Pro wird aus dem Warenkorb gelöscht.)',
                type: 'hint',
                textAlign: 'center',
                paddingBottom: '12px',
              },
              {
                button: {
                  label: 'Smartes Gerät bestellen',
                  ref: 'addToCart',
                  focus: true,
                  class: 'btn--bold btn--transparent',
                  close: true,
                  handler: () => resolve(true),
                },
              },
            ],
          },
        });
      });
    },
  },
};
