<template>
  <AddToCartButton
    :button-text="buttonText"
    :display-text="displayText"
    :display-icon="displayIcon"
    :tealium-suffix="tealiumSuffix"
    :theme="theme"
    :disabled="isDisabled"
    class="add-to-cart-button-container"
    data-testid="add-to-cart-button-container"
    @add-to-cart="prepareAddOfferToCart"
  >
    <template #badge>
      <CountBadge
        v-show="hasQuantityBadge"
        :value="cartQuantity"
        class="add-to-cart-button-container__count"
        :theme="theme"
      />
    </template>
  </AddToCartButton>
</template>

<script>
import Cookies from 'js-cookie';
import AddToCart from '@/mixins/add-to-cart';
import { AddToCartButton } from '@i22-td-smarthome/component-library';
import CountBadge from '@/components/count-badge';

export default {
  name: 'AddToCartButtonContainer',
  components: {
    AddToCartButton,
    CountBadge,
  },
  mixins: [AddToCart],
  props: {
    displayIcon: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: 'In den Warenkorb',
    },
    displayText: {
      type: Boolean,
      default: true,
    },
    displayCartQuantity: {
      type: Boolean,
      default: false,
    },
    tealiumSuffix: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'light', 'default'].includes(value);
      },
    },
  },
  computed: {
    hasQuantityBadge() {
      return this.displayCartQuantity && this.cartQuantity > 0;
    },
    buttonTitle() {
      if (!this.inStock) {
        return 'Dieser Artikel ist aktuell leider nicht verfügbar';
      }

      if (this.addAmountToCart === 1) {
        return 'Artikel in den Warenkorb legen';
      }

      return `${this.addAmountToCart} Artikel in den Warenkorb legen`;
    },
    addToCartData() {
      const salesOrganizationID = Cookies.get('TK_VO');
      return {
        articleNumber: this.articleNumber,
        contractNumber: this.contractNumber,
        quantity: this.addAmountToCart,
        salesOrganizationID,
      };
    },
  },
  methods: {
    click() {
      this.$refs.button.click();
      this.$emit('click');
    },
    async prepareAddOfferToCart() {
      await this.addOfferToCart();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.add-to-cart-button-container {
  &__count {
    overflow: visible;
    position: absolute;
    right: -10px;
    top: -10px;
  }
}
</style>
